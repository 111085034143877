@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";

@font-face {
  font-family: "WorkSans-VariableFont";
  src: url("./assets/fonts/WorkSans-VariableFont_wght.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "WorkSans-Black";
  src: url("./assets/fonts/WorkSans-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "ZenDots-Regular";
  src: url("./assets/fonts/ZenDots-Regular.ttf");
  font-weight: 400;
}

@layer utilities {
  .font-worksans {
    font-family: "WorkSans-VariableFont";
  }

  .font-worksans-black {
    font-family: "WorkSans-Black";
  }

  .font-zendots {
    font-family: "ZenDots-Regular";
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'WorkSans-VariableFont', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
  background-color: #050023;
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

select option {
  background: #050023;
}